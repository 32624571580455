<script>
import concierges from '@/data/concierges'

export default {
  name: 'Concierges',
  props: {
    incomingConcierge: {
      type: String,
      default: "" // name_lowerを受け取る
    },
    incomingGenre: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      count: 0,
      concierges: concierges,
      currentConciergeNumber: 8,
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
    }
  },
  computed: {
    skincareConcierges(){
      // 濱野先生を切り出し、かずのすけさんを先頭に
      return concierges.slice(1)
    },
  },
  mounted() {
    if(this.incomingGenre!=""){
      this.count = this.incomingGenre == "skincare" ? 0 : this.incomingGenre == "makeup" ? 1: 2
    }
    if(this.incomingConcierge!=""){
      this.currentConciergeNumber = concierges.find(
        concierge => concierge.name_lower==this.incomingConcierge).id
    }
  },
  methods: {
    // 大分類の選択
    setSkincare() {
      console.log(this.count)
      return this.count=0
    },
    setMakeup() {
      console.log(this.count)
      return this.count=1
    },
    setSkinCheck() {
      console.log(this.count)
      return this.count=2
    },
    // コンシェルジュの選択
    selectConcierge(conciergeName) {
      this.currentConciergeNumber = concierges.find(
        concierge => concierge.name_lower==conciergeName).id
    },
  }
}
</script>

<template>
  <main>
    <h1>{{ $t("conciergeTitle") }}</h1>
    <div class="concierge_genre_container">
      <div
        class="concierge_genre"
        :class="[count === 0 ? 'genre_selected' : '']"
        @click="setSkincare"
      >
        {{ $t("categorySkincare") }}
      </div>
      <div
        class="concierge_genre"
        :class="[count === 1 ? 'genre_selected' : '']"
        @click="setMakeup"
      >
        {{ $t("categoryMakeup") }}
      </div>
      <div
        class="concierge_genre"
        :class="[count === 2 ? 'genre_selected' : '']"
        @click="setSkinCheck"
      >
        {{ $t("categorySkinCheck") }}
      </div>
    </div>
    <!-- コンシェルジュのリスト -->
    <section
      v-show="count===0"
      class="concierge_skincare_container"
    >
      <div class="concierge_select_container">
        <div
          v-for="concierge in skincareConcierges"
          :key="concierge.id"
          class="concierge_select_btn"
          @click="selectConcierge(concierge.name_lower)"
        >
          <img
            class="concierge_select_btn_img"
            :class="[currentConciergeNumber === concierge.id ? 'selected_concierge_icon' : '']"
            :src="
              ROOT_IMAGE_URL
                + '/face-icon/'
                + concierge.name_lower
                + '.png'
            "
          >
          <p
            :class="[currentConciergeNumber === concierge.id ? 'selected_concierge_name' : '']"
          >
            {{ concierge.name }}
          </p>
        </div>
      </div>
    </section>
    <!-- スキンケア -->
    <section
      v-if="count===0"
      class="selected_concierge_container"
    >
      <section
        v-if="currentConciergeNumber===8"
        class="selected_concierge_kazunosuke"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <h2>{{ $t('kazunosukeName') }}</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/kazunosuke.png'
              "
            >
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info_kazunosuke">
            <div class="selected_concierge_kazunosuke_details">
              <p>{{ $t('kazunosukeDescription1') }}</p><br>
              <p>{{ $t('kazunosukeDescription2') }}</p>
              <p>{{ $t('kazunosukeDescription3') }}</p><br>
              <p>{{ $t('kazunosukeDescription4') }}</p><br>
              <p>{{ $t('kazunosukeDescription5') }}</p>
              <p>{{ $t('kazunosukeDescription6') }}</p>
            </div>
          </div>
          <section class="profile_media">
            <div class="profile_media_container">
              <div>
                <a href="https://www.youtube.com/c/kazunosuke/" class="concierge_link">
                  <img src="@/assets/logos/youtube_icon.svg">
                  <span>かずのすけチャンネル</span>
                </a>
              </div>
              <div>
                <a href="https://cores-ec.site/ceralabo/" class="concierge_link">
                  <img src="@/assets/logos/home.svg">
                  <span>CeraLabo</span>
                </a>
              </div>
              <div>
                <a href="https://kazunosuke.net/" class="concierge_link">
                  <img src="@/assets/logos/home.svg">
                  <span>Kazunosuke.net</span>
                </a>
              </div>
              <div>
                <a href="https://ameblo.jp/rik01194/" class="concierge_link">
                  <img src="@/assets/logos/home.svg">
                  <span>かずのすけの化粧品評論と美容化学についてのぼやき</span>
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===3"
        class="selected_concierge_manami"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <span>{{ $t('manamiTitle') }}</span>
            <h2>{{ $t('manamiName') }}</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/manami.png'
              "
            >
            <p>
              {{ $t('manamiShort1') }}<br>
              {{ $t('manamiShort2') }}<br>
              {{ $t('manamiShort3') }}
            </p>
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>{{ $t('profile') }}</h3>
            <p>{{ $t('manamiDescription1') }}</p>
            <p>
              {{ $t('manamiDescription2') }}
            </p>
            <h3>{{ $t('comment') }}</h3>
            <p>{{ $t('manamiComment1') }}</p>
            <p>
              {{ $t('manamiComment2') }}
            </p>
            <p>
              {{ $t('manamiComment3') }}
            </p>
            <p>
              {{ $t('manamiComment4') }}
            </p>
            <p>
              {{ $t('manamiComment5') }}
            </p>
          </div>
          <section class="profile_media">
            <div class="profile_media_container">
              <div>
                <a href="https://www.instagram.com/manami1003/?hl=ja" class="concierge_link">
                  <img src="@/assets/logos/instagram_logo.svg">
                  <span>manami1003</span>
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===4"
        class="selected_concierge_eri"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <span>{{ $t('eriTitle') }}</span>
            <h2>{{ $t('eriName') }}</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/eri.png'
              "
            >
            <p>
              {{ $t('eriShort1') }}<br>
              {{ $t('eriShort2') }}
            </p>
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>{{ $t('profile') }}</h3>
            <p>
              {{ $t('eriDescription1') }}
            </p>
            <p>{{ $t('eriDescription2') }}</p>
            <p>{{ $t('eriDescription3') }}</p>
            <p>{{ $t('eriDescription4') }}</p>
            <h4 class="profile_content_index">
              {{ $t('eriComment1') }}
            </h4>
            <p>
              {{ $t('eriComment2') }}<br>
              {{ $t('eriComment3') }}
            </p>
            <h4 class="profile_content_index">
              {{ $t('eriComment4') }}
            </h4>
            <p>
              {{ $t('eriComment5') }}
            </p>
            <h4 class="profile_content_index">
              {{ $t('eriComment6') }}
            </h4>
            <p>
              {{ $t('eriComment7') }}<br>
              {{ $t('eriComment8') }}
            </p>
          </div>
          <div class="profile_media">
            <div class="profile_media_container">
              <div>
                <a href="https://www.instagram.com/looper_hair_and_make_up/" class="concierge_link">
                  <img src="@/assets/logos/instagram_logo.svg">
                  <span>looper_hair_and_make_up</span>>
                </a>
              </div>
              <div>
                <a href="https://www.erisato.tokyo/" class="concierge_link">
                  <img src="@/assets/logos/home.svg">
                  <span>Studio&nbsp;Looper</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===5"
        class="selected_concierge_ayako"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <h2>{{ $t('ayakoName') }}</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/ayako.png'
              "
            >
            <p>
              {{ $t('ayakoShort1') }}<br>
              {{ $t('ayakoShort2') }}<br>
              {{ $t('ayakoShort3') }}
            </p>
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>{{ $t('profile') }}</h3>
            <p>
              {{ $t('ayakoDescription1') }}<br>
              {{ $t('ayakoDescription2') }}<br>
              {{ $t('ayakoDescription3') }}<br>
              {{ $t('ayakoDescription4') }}<br>
              {{ $t('ayakoDescription5') }}
            </p>
            <br>
            <h3>{{ $t('comment') }}</h3>
            <p>{{ $t('ayakoComment1') }}</p>
            <p>{{ $t('ayakoComment2') }}</p>
            <p>{{ $t('ayakoComment3') }}</p>
            <p>{{ $t('ayakoComment4') }}</p>
          </div>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===2"
        class="selected_concierge_maya"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <h2>{{ $t('mayaName') }}</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/maya.png'
              "
            >
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>{{ $t('profile') }}</h3>
            <ul class="selected_concierge_info_bullets">
              <li>{{ $t('mayaShort1') }}</li>
              <li>{{ $t('mayaShort2') }}</li>
              <li>{{ $t('mayaShort3') }}</li>
              <li>{{ $t('mayaShort4') }}</li>
              <li>{{ $t('mayaShort5') }}</li>
              <li>{{ $t('mayaShort6') }}</li>
            </ul>
            <h3>{{ $t('comment') }}</h3>
            <p>{{ $t('mayaDescription1') }}</p>
            <p>{{ $t('mayaDescription2') }}</p>
            <p>{{ $t('mayaDescription3') }}</p>
            <p>{{ $t('mayaDescription4') }}</p>
            <p>{{ $t('mayaDescription5') }}</p>
            <p>
              {{ $t('mayaDescription6') }}
            </p>
            <p>{{ $t('mayaDescription7') }}</p>
            <p>
              {{ $t('mayaDescription8') }}
            </p>
            <p>{{ $t('mayaDescription9') }}</p>
            <p>{{ $t('mayaDescription10') }}</p>
            <p>{{ $t('mayaDescription11') }}</p>
            <p>{{ $t('mayaDescription12') }}</p>
          </div>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===6"
        class="selected_concierge_nico"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <h2>{{ $t('nicoName') }}</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/nico.png'
              "
            >
            <p>{{ $t('nicoShort1') }}</p>
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>{{ $t('profile') }}</h3>
            <p>{{ $t('nicoDescription1') }}</p>
            <h3>{{ $t('comment') }}</h3>
            <p>{{ $t('nicoComment1') }}</p>
            <p>{{ $t('nicoComment2') }}</p>
            <p>{{ $t('nicoComment3') }}</p>
            <p>{{ $t('nicoComment4') }}</p>
            <p>{{ $t('nicoComment5') }}</p>
            <p>{{ $t('nicoComment6') }}</p>
          </div>
          <section class="profile_media">
            <div class="profile_media_container">
              <div>
                <a href="https://r.goope.jp/nico-gusa" class="concierge_link">
                  <img src="@/assets/logos/home.svg">
                  <span>隠れ家サロン「ヒーリングサロン和草」</span>>
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/nico_kazumi2593/" class="concierge_link">
                  <img src="@/assets/logos/instagram_logo.svg">
                  <span>nico_kazumi2593</span>>
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/nicogusa.2593/?hl=ja" class="concierge_link">
                  <img src="@/assets/logos/instagram_logo.svg">
                  <span>池尻三宿＊ヒーリングサロン和草</span>>
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>
      <section
        v-if="currentConciergeNumber===7"
        class="selected_concierge_peki"
      >
        <div class="selected_concierge_infoSnippet_container">
          <div class="selected_concierge_infoSnippet">
            <h2>{{ $t('pekiName') }}</h2>
            <img
              :src="
                ROOT_IMAGE_URL
                  + '/face-icon/peki.png'
              "
            >
          </div>
        </div>
        <div class="selected_concierge_info_container">
          <div class="selected_concierge_info">
            <h3>{{ $t('profile') }}</h3>
            <p>
              {{ $t('pekiShort1') }}<br>
              {{ $t('pekiShort2') }}
            </p>
            <p>
              {{ $t('pekiDescription1') }}<br>
              {{ $t('pekiDescription2') }}<br>
              {{ $t('pekiDescription3') }}
            </p>
            <h4 class="profile_content_index">
              {{ $t('pekiDescription4') }}
            </h4>
            <p>{{ $t('pekiDescription5') }}</p>
            <h4 class="profile_content_index">
              {{ $t('pekiDescription6') }}
            </h4>
            <p>{{ $t('pekiDescription7') }}</p>
            <h4 class="profile_content_index">
              {{ $t('pekiDescription8') }}
            </h4>
            <p>{{ $t('pekiDescription9') }}</p>
            <h4 class="profile_content_index">
              {{ $t('pekiDescription10') }}
            </h4>
            <p>{{ $t('pekiDescription11') }}</p>
          </div>
        </div>
      </section>
    </section>
    <!-- メイク -->
    <section
      v-if="count===1"
      class="concierge_makeup_container"
    >
      <div class="concierge_inner">
        <div class="selected_concierge_eri">
          <div class="selected_concierge_infoSnippet_container">
            <div class="selected_concierge_infoSnippet">
              <span>{{ $t('eriTitle') }}</span>
              <h2>{{ $t('eriName') }}</h2>
              <img
                :src="
                  ROOT_IMAGE_URL
                    + '/face-icon/eri.png'
                "
              >
              <p>
                {{ $t('eriShort1') }}<br>
                {{ $t('eriShort2') }}
              </p>
            </div>
          </div>
          <div class="selected_concierge_info_container">
            <div class="selected_concierge_info">
              <h3>{{ $t('profile') }}</h3>
              <p>
                {{ $t('eriDescription1') }}
              </p>
              <p>{{ $t('eriDescription2') }}</p>
              <p>{{ $t('eriDescription3') }}</p>
              <p>{{ $t('eriDescription4') }}</p>
              <h4 class="profile_content_index">
                {{ $t('eriComment1') }}
              </h4>
              <p>
                {{ $t('eriComment2') }}<br>
                {{ $t('eriComment3') }}
              </p>
              <h4 class="profile_content_index">
                {{ $t('eriComment4') }}
              </h4>
              <p>
                {{ $t('eriComment5') }}
              </p>
              <h4 class="profile_content_index">
                {{ $t('eriComment6') }}
              </h4>
              <p>
                {{ $t('eriComment7') }}<br>
                {{ $t('eriComment8') }}
              </p>
            </div>
            <section class="profile_media">
              <div class="profile_media_container">
                <div>
                  <a href="https://www.instagram.com/looper_hair_and_make_up/" class="concierge_link">
                    <img src="@/assets/logos/instagram_logo.svg">
                    <span>looper_hair_and_make_up</span>
                  </a>
                </div>
                <div>
                  <a href="https://www.erisato.tokyo/" class="concierge_link">
                    <img src="@/assets/logos/home.svg">
                    <span>Studio&nbsp;Looper</span>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
    <!-- 肌チェック -->
    <section
      v-if="count===2"
      class="concierge_dermatologist_container"
    >
      <div class="concierge_inner">
        <div class="selected_concierge_hamano">
          <div class="selected_concierge_infoSnippet_container">
            <div class="selected_concierge_infoSnippet">
              <span>{{ $t('hamanoTitle') }}</span>
              <h2>{{ $t('hamanoName') }}</h2>
              <img
                :src="
                  ROOT_IMAGE_URL
                    + '/face-icon/hamano.png'
                "
              >
            </div>
          </div>
          <div class="selected_concierge_info_container">
            <div class="selected_concierge_info">
              <h3>{{ $t('profile') }}</h3>
              <h4 class="profile_content_index">
                {{ $t('hamanoHistory1') }}
              </h4>
              <p>
                {{ $t('hamanoHistory2') }}<br>
                {{ $t('hamanoHistory3') }}<br>
                {{ $t('hamanoHistory4') }}<br>
                {{ $t('hamanoHistory5') }}<br>
                {{ $t('hamanoHistory6') }}<br>
                {{ $t('hamanoHistory7') }}<br>
              </p>
              <h4 class="profile_content_index">
                {{ $t('hamanoAffiliated1') }}
              </h4>
              <p>
                {{ $t('hamanoAffiliated2') }}<br>
                {{ $t('hamanoAffiliated3') }}<br>
                {{ $t('hamanoAffiliated4') }}<br>
                {{ $t('hamanoAffiliated5') }}<br>
                {{ $t('hamanoAffiliated6') }}<br>
                {{ $t('hamanoAffiliated7') }}<br>
                {{ $t('hamanoAffiliated8') }}<br>
                {{ $t('hamanoAffiliated9') }}
              </p>
            </div>
            <section class="profile_media">
              <div class="profile_media_container">
                <div>
                  <a href="https://www.thetis-y.com/" class="concierge_link">
                    <img src="@/assets/logos/home.svg">
                    <span>テティス横濱美容皮膚科</span>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
h1{
  font-size: 24px;
  padding: 45px 0 45px 0;
  text-align: center;
}
h2{
  font-size: 26px;
}
h3{
  font-size: 22px;
  padding-bottom: 20px;
  font-weight: bold;
}
.concierge_genre{
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 50px;
  width: 33.3%;
  border: 1px solid #F5EAE7;
}
.genre_selected{
  background-color: #F5EAE7;
}
.concierge_skincare_container{
  padding: 0 40px;
  background-color: #F5EAE7;
}
.concierge_select_container{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
}
.concierge_select_btn{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
}
.concierge_select_btn_img{
  border-radius: 50%;
  border: solid 1px #CCC;
  width: 60px;
  /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%); */
}
.selected_concierge_icon{
  width: 75px;
  border: solid 2px #F29F8F;
}
.selected_concierge_name{
  font-weight: bold;
}
.concierge_select_btn p{
  font-size: 14px;
  padding-top: 5px;
}
.selected_concierge_container{
  background-color: #F5EAE7;
}
.selected_concierge_infoSnippet{
  background-color: #F5EAE7;
}
.selected_concierge_infoSnippet_container{
  background-color: #F5EAE7;
  padding: 0 40px;
}
.selected_concierge_infoSnippet{
  padding: 30px 0;
}
.selected_concierge_infoSnippet span{
  font-size: 14px;
}
.selected_concierge_infoSnippet h2{
  font-size: 26px;
  font-weight: bold;
  padding: 5px 0;
}
.selected_concierge_infoSnippet img{
  margin: 15px 0;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}
.selected_concierge_info_container{
  padding: 0 40px;
  background-color: #FFF;
}
.selected_concierge_info{
  padding: 50px 0 70px 0;
}
.selected_concierge_info p{
  font-size: 14px;
  line-height: 1.4;
  padding-bottom: 20px;
}
.selected_concierge_info p:last-child{
  font-size: 14px;
  line-height: 1.4;
  padding-bottom: 0px;
}

/* kazunosuke */
.selected_concierge_info_kazunosuke{
  padding: 30px 0 10px 0;
}
.selected_concierge_info_kazunosuke h4{
  font-size: 18px;
  padding-bottom: 10px;
}
.selected_concierge_info_kazunosuke p{
  font-size: 14px;
  padding-bottom: 4px;
}
.selected_concierge_kazunosuke_details{
  font-size: 14px;
  padding-bottom: 4px;
}
.selected_concierge_kazunosuke_details:last-child{
  font-size: 14px;
  padding-bottom: 70px;
}

.selected_concierge_info_bullets{
  padding-bottom: 20px;
}
.selected_concierge_info_bullets li{
  font-size: 14px;
  line-height: 1.4;
  list-style: square inside;
  text-indent: -1.4em;
  padding-left: 1em;
  padding-bottom: 7px;
}
.profile_content_index{
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 25px;
}
.profile_media_container{
  padding: 0 0 70px 0;
}
.concierge_link{
  display: flex;
  color: #F29F8F;
  padding-bottom: 30px;
}
.concierge_link div:last-child a{
  display: inline-block;
  color: #F29F8F;
  padding-bottom: 0px;
}
.concierge_link img{
  width: 16px;
}
.concierge_link span{
  font-size: 14px;
  padding-left: 20px;
}
</style>
